<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="6" lg="4">
            <v-card class="elevation-12" :loading="loading">
              <v-form v-model="valid" :lazy-validation="false" @submit.prevent="_login">
                <v-toolbar color="primary" dark flat>
                  <v-toolbar-title>Login</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                  <v-text-field
                    label="Email"
                    name="email"
                    prepend-icon="mdi-account"
                    type="email"
                    v-model="form.email"
                    :rules="rules.email"
                  ></v-text-field>

                  <v-text-field
                    label="Password"
                    name="password"
                    prepend-icon="mdi-lock"
                    v-model="form.password"
                    :append-icon="pshow ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="pshow ? 'text' : 'password'"
                    @click:append="pshow = !pshow"
                    :rules="rules.password"
                  ></v-text-field>

                  <v-layout justify-start class="ml-8">
                    <a href @click.prevent="_forgot">Forgot Password?</a>
                  </v-layout>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" type="submit" :loading="loading" :disabled="!valid">Login</v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Vue from "vue";
import Auth from "@/store/models/Auth";

export default {
  components: {},
  data: function () {
    return {
      loading: false,
      pshow: false,
      form: {
        email: "",
        password: "",
      },
      rules: {
        email: [
          (v) => !!v || "E-mail is required",
          (v) => this.$regexEmail.test(v) || "E-mail must be valid",
        ],
        password: [
          (v) => !!v || "Password is required",
          (v) => (v || "").length >= 6 || "Min 6 characters",
          (v) => (v || "").length <= 20 || "Max 20 characters",
          (v) => this.$regexPwd.test(v) || this.$regexPwdTxt,
        ],
      },
      valid: false,
    };
  },
  computed: {},
  methods: {
    _login() {
      if (!this.valid) {
        return;
      }

      this.loading = true;
      const options = {
        data: this.form,
      };

      Auth.login(options)
        .then(async (res) => {
          if (res.accessToken) {
            this.$router.push({ path: `/` }).then(() => (this.loading = false));
          }
          this._profile();
          return res;
        })
        .catch((err) => {
          console.error("Login error: ", err);
          this.loading = false;
          return err;
        });
    },
    _forgot() {
      this.$router.push({ path: `/forgot/password` });
    },
    async _profile() {
      const token = this.$ls.get("token");
      if (token) {
        const profile = await Auth.profile().catch((err) =>
          console.error("Get profile error: ", err)
        );
        Vue.prototype.$profile = profile;
      }
    },
  },
};
</script>